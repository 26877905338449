import {Container, Row, Col, Card} from 'react-bootstrap'

import bookShelf from '../images/bookshelf.jpg';

const Home = () => {

    return (
        <Container className="mt-5">
            <Row>
                <Col>
                    <Card className="mt-5">
                        <Card.Img variant='bottom' src={bookShelf} alt='Books!'/>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;