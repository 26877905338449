import {useState, useEffect} from "react";
import axios from 'axios';
import Table from 'react-bootstrap/Table'
import Pagination from "./Pagination";

const ListTitles = () => {
    const [titles, setTitles] = useState([]);
    const [maxPageNumber, setMaxPageNumber] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = process.env.REACT_APP_PAGE_SIZE;

    useEffect(() => {
        axios.get("/api/list/title?page="+(currentPage-1)+"&size="+pageSize)
            .then(resp => {
                setTitles(resp.data.items)
                setMaxPageNumber(Math.ceil(resp.data.count / pageSize));
            })
            .catch(error => console.error(error))
    }, [currentPage])

    return (
        <>
            <Table striped bordered size="sm">
                <tbody>
                {titles.map(t => (
                    <tr key={t.id}>
                        <td><a href={'/book/' +t.id}>{t.nonSort !== '' && t.nonSort}{t.title}{t.subTitle !== '' && ': '+t.subTitle}</a></td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {maxPageNumber > 1 &&
                <div>
                    <Pagination currentPage={currentPage} maxPageNumber={maxPageNumber} onPageChange={page => setCurrentPage(page)}/>
                </div>}
        </>
    )
}

export default ListTitles;
