import {useState, useEffect} from "react";
import axios from 'axios';
import Table from 'react-bootstrap/Table'
import Pagination from "./Pagination";

const ListCreators = () => {
    const [maxPageNumber, setMaxPageNumber] = useState(0);
    const [creators, setCreators] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = process.env.REACT_APP_PAGE_SIZE;

    useEffect(() => {
        axios.get("/api/list/creator?page="+(currentPage-1)+"&size="+pageSize)
            .then(resp => {
                setCreators(resp.data.items);
                setMaxPageNumber(Math.ceil(resp.data.count / pageSize));
            })
            .catch(error => console.error(error))
    }, [currentPage])

    return (
        <>
            <Table striped bordered size="sm">
                <tbody>
                {creators.map(c => (
                    <tr key={c.id}>
                        <td><a
                            href={'/creator/' + c.id}>{c.name}</a>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {maxPageNumber > 1 &&
            <div>
                <Pagination currentPage={currentPage} maxPageNumber={maxPageNumber} onPageChange={page => setCurrentPage(page)}/>
            </div>}
        </>);
}
export default ListCreators;