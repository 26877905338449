import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";

const Creator = () => {
    const {id} = useParams();
    const [creator, setCreator] = useState({name:"",items:[]});

    useEffect(() => {
        axios.get("/api/creator/"+id)
            .then(resp => {
                setCreator(resp.data)
            })
            .catch(error => console.error(error))
    }, [id])

    return (
        <>
            <h3>{creator.name}</h3>
            <Table striped bordered size="sm">
                <tbody>
                {creator.items.map(c => (
                    <tr key={c.id}>
                        <td><a
                            href={"/"+c.url}>{c.title}</a>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>);

}

export default Creator;