import './App.css';
import {Route, Routes} from 'react-router-dom';
import Search from './Components/Search';
import ListCreators from './Components/ListCreators';
import ListTitles from './Components/ListTitles';
import ListSubjects from './Components/ListSubjects'
import Home from './Components/Home';
import Navigation from "./Components/Navigation";
import NotFound from "./Components/NotFound";
import Creator from "./Components/Creator";
import Subject from "./Components/Subject";
import Book from "./Components/Book";
import React from "react";
import ListByLccn from "./Components/ListByLccn";
import ListByDewey from "./Components/ListByDewey";
import Login from "./Components/Login";
import {AuthProvider} from "./Components/AuthProvider";

function App() {
    return (
        <div className="App">
            <AuthProvider>
                <Navigation/>
                <Routes>
                    <Route path='*' element={<NotFound/>}/>
                    <Route exact path='/' element={<Home/>}/>
                    <Route path='/search' element={<Search/>}/>
                    <Route path='/list/creators' element={<ListCreators/>}/>
                    <Route path='/list/titles' element={<ListTitles/>}/>
                    <Route path='/list/subjects' element={<ListSubjects/>}/>
                    <Route path='/creator/:id' element={<Creator/>}/>
                    <Route path='/subject/:id' element={<Subject/>}/>
                    <Route path='/book/:id' element={<Book/>}/>
                    <Route path='/list/lccn' element={<ListByLccn/>}/>
                    <Route path='/list/dewey' element={<ListByDewey/>}/>
                    <Route path='/login' element={<Login/>}/>
                </Routes>
            </AuthProvider>
        </div>
    );
}

export default App;
