import {Navbar, Nav, Container, NavDropdown} from 'react-bootstrap';
import {useAuth} from "./AuthContext";

const Navigation = () => {
    const {auth, admin} = useAuth();
    const bgColor = process.env.REACT_APP_NAVBAR_BG_COLOR;
    return (
        <>
            <Navbar collapseOnSelect fixed='top' expand='md' style={{backgroundColor: bgColor}}>
                <Container>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Navbar.Brand href='/'>Library</Navbar.Brand>
                        <Nav>
                            <Nav.Link href='/search'>Search the Catalog</Nav.Link>
                            <Nav.Link href='/list/creators'>Authors</Nav.Link>
                            <Nav.Link href='/list/titles'>Titles</Nav.Link>
                            <Nav.Link href='/list/subjects'>Subjects</Nav.Link>
                            <NavDropdown title="List by">
                                <NavDropdown.Item href="/list/lccn">LCC</NavDropdown.Item>
                                <NavDropdown.Item href="/list/dewey">Dewey</NavDropdown.Item>
                            </NavDropdown>
                            {!auth && <Nav.Link href='/login'>Login</Nav.Link>}
                            {auth && <Nav.Link href='/logout'>Logout</Nav.Link>}
                            {auth &&
                                <Nav>
                                    <Nav.Link className="justify-content-end" href="/user">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor"
                                             className="bi bi-person-circle" viewBox="0 0 16 16">
                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                            <path fillRule="evenodd"
                                                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                        </svg>
                                    </Nav.Link>
                                </Nav>}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Navigation;

