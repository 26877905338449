import React, {useContext} from 'react';

export const AuthContext = React.createContext({
    auth: false,
    setAuth: () => {},
    admin: false,
    setAdmin: () => {},
});

export let useAuth;
useAuth = () => useContext(AuthContext);