import {useState} from "react";
import {Container} from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import axios from "axios";

const Search = () => {
    const {prevQuery, prevResults} = JSON.parse(localStorage.getItem("searchDetails") ?
        localStorage.getItem("searchDetails"):"{\"prevQuery\":\"\",\"prevResults\":\"\"}");
    const [searchText, setSearchText] = useState(prevQuery ? prevQuery:"");
    const [searchResults, setSearchResults] = useState(prevResults ? prevResults:[]);
    const [searchSubmitted, setSearchSubmitted] = useState(prevQuery.length !== 0);

    const performSearch = async (e) => {
        e.preventDefault();
        axios.get('/api/search?topic=' + searchText).then(resp => {
            setSearchResults(resp.data);
            setSearchSubmitted(true);
            localStorage.setItem("searchDetails", JSON.stringify({prevQuery:searchText,prevResults:resp.data}));
        })
            .catch(error => console.error(error))
    }

    return (
        <>
            <Container className="d-flex align-items-center justify-content-center">
                <form className="form-text w-75 m-auto" onSubmit={performSearch}>
                    <Table borderless>
                        <tbody>
                        <tr>
                            <td>
                                <input type="text" className="form-control" placeholder="Search term" name="searchTerm"
                                       onChange={e => setSearchText(e.target.value)} required
                                       autoFocus/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button type="submit" className="w-25 btn btn-primary my-2 my-sm-0">Search</button>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </form>
            </Container>
            <Table borderless>
                <tbody>
                {!searchSubmitted ?  <tr></tr> : searchResults.length === 0 ? <tr>No Results</tr> :
                searchResults.map(sr => (
                    <tr key={sr.topic}>
                        <td><a href={sr.url}>{sr.topic}</a></td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );

}

export default Search;