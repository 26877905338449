import {useState, useEffect} from "react";
import axios from 'axios';
import Table from 'react-bootstrap/Table'
import Pagination from "./Pagination";

const ListSubjects = () => {
    const [subjects, setSubjects] = useState([]);
    const [maxPageNumber, setMaxPageNumber] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = process.env.REACT_APP_PAGE_SIZE;

    useEffect(() => {
        axios.get("/api/list/subject?page=" + (currentPage-1) + "&size=" + pageSize)
            .then(resp => {
                setSubjects(resp.data.items);
                setMaxPageNumber(Math.ceil(resp.data.count / pageSize));
            })
            .catch(error => console.error(error))
    }, [currentPage])

    return (
        <>
            <Table striped bordered size="sm">
                <tbody>
                {subjects.map(s => (
                    <tr key={s.subjectListId}>
                        <td><a
                            href={'/subject/' + s.subjectListId}>{s.subject}</a>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {maxPageNumber > 1 &&
                <div>
                    <Pagination currentPage={currentPage} maxPageNumber={maxPageNumber}
                                onPageChange={page => setCurrentPage(page)}/>
                </div>}
        </>);
}
export default ListSubjects;