import React from "react";

const NotFound = () => {
    return (<>
        <main className="px-3">
            <h1>Not Found</h1>
            <p className="lead">This page cannot be found.</p>
        </main>
    </>)
}

export default NotFound;
