import React, {useEffect, useState} from 'react';
import {AuthContext} from './AuthContext'
export const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState(localStorage.getItem('authenticated') === 'true');
    const [admin, setAdmin] = useState(localStorage.getItem('admin') === 'true');

    useEffect(() => {
        setAuth(localStorage.getItem('authenticated') === 'true');
        setAdmin(localStorage.getItem('admin') === 'true');
    },[auth,admin]);

    return (
        <AuthContext.Provider value = {{auth, setAuth, admin, setAdmin}}>
            {children}
        </AuthContext.Provider>
    );
}

export const AuthConsumer = AuthContext.Consumer;