import {Pagination as BootstrapPagination} from "react-bootstrap";

const Pagination = props => {
    const {
        currentPage,
        maxPageNumber,
        onPageChange,
    } = props;

    if (currentPage === 0 || maxPageNumber < 2) {
        return null;
    }

    const nextPage = () => {
        if (currentPage < maxPageNumber)
            onPageChange(currentPage + 1);
    }

    const prevPage = () => {
        if (currentPage > 1)
            onPageChange(currentPage - 1);
    }

    const goToFirst = () => {
        onPageChange(1);
    }

    const goToLast = () => {
        onPageChange(maxPageNumber)
    }
    const goToPage = (num) => {
        if (num >= 1 || num <= maxPageNumber)
            onPageChange(num);
    }

    let items = [];
    items.push(<BootstrapPagination.First onClick={goToFirst}/>);
    items.push(<BootstrapPagination.Prev onClick={prevPage}/>);
    for (let number = 1; number <= maxPageNumber; number++) {
        items.push(
            <BootstrapPagination.Item key={number} active={number === currentPage} onClick={() => goToPage(number)}>
                {number}
            </BootstrapPagination.Item>
        );
    }
    items.push(<BootstrapPagination.Next onClick={nextPage}/>)
    items.push(<BootstrapPagination.Last onClick={goToLast}/>)

    return (
        <BootstrapPagination className="justify-content-center">
            {items}
        </BootstrapPagination>
    );
}

export default Pagination;