import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";

const Subject = () => {
    const {id} = useParams();
    const [subject, setSubject] = useState({name:"",items:[]});

    useEffect(() => {
        axios.get("/api/subject/"+id)
            .then(resp => {
                setSubject(resp.data)
            })
            .catch(error => console.error(error))
    }, [id])

    return (
        <>
            <h3>{subject.name}</h3>
            <Table striped bordered size="sm">
                <tbody>
                {subject.items.map(s => (
                    <tr key={s.id}>
                        <td><a
                            href={"/"+s.url}>{s.title}</a>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>);

}

export default Subject;