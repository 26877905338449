import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {Card} from "react-bootstrap";

const Book = () => {
    const {id} = useParams();
    const [book, setBook] = useState({"title": "", "lines": []});

    useEffect(() => {
        axios.get("/api/book/" + id)
            .then(resp => {
                setBook(resp.data)
            })
            .catch(error => console.error(error))
    }, [id])

    return (
            <div className="d-flex justify-content-center">
                <Card style={{width: '54rem'}}>
                    <Card.Title>{book.title}</Card.Title>
                    {book.lines.slice(1).map(l => (
                        <Card.Text>{l}</Card.Text>
                    ))}
                </Card>
            </div>
        );
}

export default Book;